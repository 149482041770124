/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/htmx.org@1.9.12/dist/htmx.min.js
 * - /npm/idiomorph@0.3.0/dist/idiomorph-ext.min.js
 * - /npm/htmx-ext-alpine-morph@2.0.0/alpine-morph.min.js
 * - /npm/@alpinejs/morph@3.14.3/dist/cdn.min.js
 * - /npm/@alpinejs/intersect@3.13.10/dist/cdn.min.js
 * - /npm/@alpinejs/persist@3.13.10/dist/cdn.min.js
 * - /npm/@alpinejs/collapse@3.13.10/dist/cdn.min.js
 * - /npm/@alpinejs/mask@3.13.10/dist/cdn.min.js
 * - /npm/@alpinejs/focus@3.13.10/dist/cdn.min.js
 * - /npm/alpinejs@3.13.10/dist/cdn.min.js
 * - /npm/keen-slider@6.8.6/keen-slider.min.js
 * - /npm/maplibre-gl@4.0.2/dist/maplibre-gl.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
